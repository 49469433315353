<template>
    <v-container fluid>
        <!-- Key points -->
        <v-row no-gutters class="mb-4">
            <v-col xs="12" md="3">
                <v-card>
                    <v-card-text class="text-center display-1 font-weight-light" v-if="event != null">
                        {{ event.foreignKey }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="grey lighten-3">
                        <div class="text-center subheading" style="width:100%">
                            Event ID
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col xs="12" md="3" class="pl-3">
                <v-card>
                    <v-card-text class="text-center display-1 font-weight-light" v-if="event != null">
                        {{ event.eventType }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="grey lighten-3">
                        <div class="text-center subheading" style="width:100%">
                            Type
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col xs="12" md="3" class="pl-2">
                <v-card>
                    <v-card-text class="text-center" v-if="event != null">
                        <span class="display-1 font-weight-light">{{ formatDate(event.eventDate, 'YYYY-MM-DD') }}</span>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="grey lighten-3">
                        <div class="text-center subheading" style="width:100%">
                            Event Date
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col xs="12" md="3" class="pl-3">
                <v-card>
                    <v-card-text class="text-center display-1 font-weight-light" v-if="event != null">
                        {{ formatDate(event.dateCreated, 'YYYY-MM-DD') }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="grey lighten-3">
                        <div class="text-center subheading" style="width:100%">
                            Date Created
                        </div>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col cols="9">
                <v-card flat class="mr-3" :loading="loading">
                    <v-card-title class="subtitle-1 grey--text" v-if="event != null">
                        {{ event.eventType }} @ {{ event.location }}
                        <v-spacer></v-spacer>
                        <span class="subtitle-1">Created by: {{ event.employee.fullName }}</span>
                    </v-card-title>
                    <v-card-text v-if="event != null">
                        <v-row fill-height>
                            <v-col xs="12" md="4">
                                <div class="grey lighten-5 pa-2 elevation-2">
                                    <div class="headline font-weight-light mb-5">
                                        {{ organizersTitle }}
                                    </div>

                                    <v-expansion-panels accordion flat dense class="transparent panel">
                                        <template v-for="organizer in organizers">
                                            <v-expansion-panel :key="organizer.id">
                                                <v-expansion-panel-header>
                                                    <div>{{ organizer.firstName + ' ' + organizer.lastName }}</div>
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-card flat>
                                                        <v-card-text class="pa-0">
                                                            <v-row>
                                                                <v-col xs="12" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pr-1' : ''">
                                                                    <v-text-field label="First Name" v-model="organizer.firstName" readonly></v-text-field>
                                                                </v-col>
                                                                <v-col xs="12" md="6" :class="$vuetify.breakpoint.mdAndUp ? 'pl-1' : ''">
                                                                    <v-text-field label="Last Name" v-model="organizer.lastName" readonly></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12">
                                                                    <v-text-field label="Phone" mask="phone" v-model="organizer.phone" readonly></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12">
                                                                    <v-text-field label="Email" v-model="organizer.email" readonly></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12">
                                                                    <v-textarea label="Comment" v-model="organizer.comment" rows="3" readonly></v-textarea>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </template>
                                    </v-expansion-panels>
                                </div>
                            </v-col>

                            <v-col xs="12" md="4">
                                <div class="headline font-weight-light mb-5">
                                    Additional Information
                                </div>

                                <v-row class="mt-1">
                                    <v-col cols="6" class="pr-2">
                                        <v-text-field v-model="event.guestQty" label="How many guests are expected?" readonly hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="pl-2">
                                        <v-text-field v-model="event.dressCode" label="What is the dress code?" readonly hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Event Coordinator" v-model="event.eventCoordinatorFullName" readonly hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field :label="eventCompanyLabel" v-model="event.company" readonly hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Referral Source" v-model="event.referralSource" readonly hide-details></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea label="Event Comments" v-model="event.comment" rows="3" readonly hide-details></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col xs="12" md="4">
                                <div class="headline font-weight-light mb-5">
                                    Default Style
                                </div>

                                <package-viewer :value="defaultPackage" />
                            </v-col>
                        </v-row>

                        <div class="mt-4">
                            <div class="headline font-weight-light mb-5">
                                Guest List
                            </div>

                            <v-data-table :headers="guestHeaders" :items="event.participants">
                                <template v-slot:item.customer.phone="{ item }">
                                    {{ formatPhoneNum( item.customer.phone ) }}
                                </template>
                                <template v-slot:item.style="{ item }">
                                    {{ participantStyle(item) }}
                                </template>
                                <template v-slot:item.subtotal="{ item }">
                                    {{ formatMoney( mxSubtotal(item.style) ) }}
                                </template>
                                <template v-slot:item.discounts="{ item }">
                                    {{ formatMoney( mxTotalPromoDiscount(item.discountCode) ) }}
                                </template>
                                <template v-slot:item.total="{ item }">
                                    {{ formatMoney( mxTotal(item.style, item.discountCode, item.charges) ) }}
                                </template>
                                <template v-slot:item.orderForeignId="{ item }">
                                    <v-tooltip top v-if="item.orderId != 0">
                                        <template v-slot:activator="{ on }">
                                            <v-btn text depressed small :to="'/orders/' + item.orderId" v-on="on"
                                                :color="orderStatusColor(item.orderStatus)">{{ item.orderForeignId }}</v-btn>
                                        </template>
                                        <span>{{ item.orderStatus }}</span>
                                    </v-tooltip>
                                    
                                    <v-btn text small :to="'/reservations/' + item.reservationId" v-else>
                                        <v-icon left small>mdi-open-in-new</v-icon> Reservation
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </div>

                        <div class="mt-4">
                            <div class="headline font-weight-light mb-5">
                                Guest Packages
                            </div>

                            <v-row no-gutters>
                                <v-col cols="4">
                                    <div class="title text-center">Package #1</div>
                                    <package-viewer :value="package1" />
                                </v-col>
                                <v-col cols="4">
                                    <div class="title text-center">Package #2</div>
                                    <package-viewer :value="package2" />
                                </v-col>
                                <v-col cols="4">
                                    <div class="title text-center">Package #3</div>
                                    <package-viewer :value="package3" />
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="3">
                <v-card flat>
                    <v-card-title class="headline font-weight-light grey--text text--darken-4">History</v-card-title>
                    <v-card-text class="px-0">
                        <history obj-type="event" :id="eventId"  :showForm="false" ref="history" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { API } from '@/inc/api';
import History from '@/components/History.vue';
import PackageViewer from '@/components/PackageViewer.vue';
import moment from 'moment';
import { _st } from '@/softech';

export default {
    props: {
        eventId: { type: [String, Number], default: 0 }
    },
    data: () => ({
        event           : null,
        loading         : false,

        guestHeaders  : [
            { text: 'Customer Name', value: 'customer.fullName', sortable: true },
            { text: 'Phone', value: 'customer.phone', sortable: true },
            { text: 'Type', value: 'customerRoleId', sortable: true },
            { text: 'Style', value: 'style', align: 'right', sortable: true },
            { text: 'Subtotal', value: 'subtotal', align: 'right', sortable: true },
            { text: 'Discounts', value: 'discounts', align: 'right', sortable: true },
            { text: 'Total', value: 'total', align: 'right', sortable: true },
            { text: 'Comments', value: 'comment', sortable: true },
            { text: 'Order #', value: 'orderForeignId', align: 'right', sortable: true },
        ],
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        pagination      : {
            sortBy: ['type']
        },
    }),
    computed: {
        organizersTitle() {
            if( this.event.type == 1 )
                return "Couple Information";
            else
                return "Organizers Information";
        },
        organizers() {
            if( this.event == null )
                return [];

            if( this.event.coordinator2 == null )
                return [ this.event.coordinator1 ];

            return [
                this.event.coordinator1,
                this.event.coordinator2
            ];
        },
        eventCompanyLabel() {
            if( this.event.type == 1 )
                return "Wedding Coordinator";
            else
                return "Company or Institution";
        },
        defaultPackage() {
            if( this.event == null )
                return {};

            return {
                items                   : this.event.defaultStyle,
                promoCodes              : this.event.discountCode,
                charges                 : [],
                subtotalOverwrite       : null,
                subtotalOverwriteReason : null
            };
        },
        package1() {
            if( this.event == null ) {
                return { items: [], promoCodes: [], charges: [], subtotalOverwrite: null, subtotalOverwriteReason: null };
            }

            return {
                items                   : this.event.guestDefaultStyle1,
                promoCodes              : this.event.guestDefaultPromo1,
                charges                 : this.event.guestDefaultCharge1,
                subtotalOverwrite       : this.event.guestsubtotalOverwrite1,
                subtotalOverwriteReason : this.event.guestSubtotalOverwriteReason1
            };
        },
        package2() {
            if( this.event == null ) {
                return { items: [], promoCodes: [], charges: [], subtotalOverwrite: null, subtotalOverwriteReason: null };
            }

            return {
                items                   : this.event.guestDefaultStyle2,
                promoCodes              : this.event.guestDefaultPromo2,
                charges                 : this.event.guestDefaultCharge2,
                subtotalOverwrite       : this.event.guestSubtotalOverwrite2,
                subtotalOverwriteReason : this.event.guestSubtotalOverwriteReason2
            };
        },
        package3() {
            if( this.event == null ) {
                return { items: [], promoCodes: [], charges: [], subtotalOverwrite: null, subtotalOverwriteReason: null };
            }

            return {
                items                   : this.event.guestDefaultStyle3,
                promoCodes              : this.event.guestDefaultPromo3,
                charges                 : this.event.guestDefaultCharge3,
                subtotalOverwrite       : this.event.guestSubtotalOverwrite3,
                subtotalOverwriteReason : this.event.guestSubtotalOverwriteReason3
            };
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/admin/event/${this.eventId}`);
                this.loading = false;

                if( res.data.status === false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                if( _st.isNUE( res.data.data.id ) ) {
                    this.$router.push('/404');
                    return;
                }

                this.event = res.data.data;
                this.$refs.history.getHistory();
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        formatDate(d, f = 'YYYY-MM-DD @ hh:mm a') {
            return moment(d).format(f);
        },
        participantStyle(item) {
            return _st.extractProp( item.style, 'style' ).join(', ');
        },

    },
    components: {
        History, PackageViewer
    }
}
</script>

<style lang="scss" scoped>
.v-expansion-panel-content__wrap {
    padding: 0 !important;
}
</style>